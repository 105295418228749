@font-face {
    font-family: 'Roboto-Light';
    src: url('../fonts/Roboto-Light.eot');
    src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto-Light.woff') format('woff'),
        url('../fonts/Roboto-Light.ttf') format('truetype'),
        url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Roboto-Medium';
    src: url('../fonts/Roboto-Medium.eot');
    src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff'),
        url('../fonts/Roboto-Medium.ttf') format('truetype'),
        url('../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../fonts/Roboto-Bold.eot');
    src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto-Bold.ttf') format('truetype'),
        url('../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.eot');
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('truetype'),
        url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


/* FONT */
.font-weight-bold {
    font-weight: bold;
}
.font-weight-normal {
    font-weight: normal;
}
.font-weight-light {
    font-weight: lighter;
}
.font-italic {
    font-style: italic;
}
.font-normal {
    font-style: normal;
}

/* FONT WIGHT */
.font-weight-400 {
    font-weight: 400;
}
.font-weight-500 {
    font-weight: 500;
}
.font-weight-600 {
    font-weight: 600;
}
.font-weight-700 {
    font-weight: 700;
}

/* FONT SIZE */
.font-size-10 {
    font-size: 10px;
}
.font-size-11 {
    font-size: 11px;
}
.font-size-12 {
    font-size: 12px;
}
.font-size-13 {
    font-size: 13px;
}
.font-size-14 {
    font-size: 14px;
}
.font-size-15 {
    font-size: 15px;
}
.font-size-16 {
    font-size: 16px;
}
.font-size-17 {
    font-size: 17px;
}
.font-size-18 {
    font-size: 18px;
}
.font-size-20 {
    font-size: 20px;
}