/* Colors */
.primary {
    color: #17151D;
}

/* Backgrounds */
.primary-background {
    background-color: #17151D;
}

.text-blue {
    color: #003DB7;
}