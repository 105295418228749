:root {
    --heading1-font: 48px;
    --heading2-font: 32px;
    --heading3-font: 25px;
    --heading4-font: 20px;
    --heading5-font: 17px;
    --heading6-font: 16px;

    --heading1-lineheight: 65px;
    --heading2-lineheight: 55px;
    --heading3-lineheight: 35px;
    --heading4-lineheight: 28px;
    --heading5-lineheight: 30px;
    --heading6-lineheight: 19px;

    --primary-color: #8574B5;
    --secondary-color: #1C2C50;

    --blue-1: #1C2C50;
    --blue-2: #475F94;
    --blue-3: #859ED8;
    --blue-4: #C0D3FF;

    --grey-1: #3B3F46;
    --grey-2: #61666F;
    --grey-3: #8A8F98;
    --grey-4: #B5B9C1;
    --grey-5: #E3E5E9;

    --color-white: #FFFFFF;
    --app-background-color: #010C24; /* This is app background color */
    --font-roboto-light: "Roboto-Light";
    --font-roboto-medium: "Roboto-Medium";
    --font-roboto-bold: "Roboto-Bold";
    --font-roboto: "Roboto";
}

body {
    font-family: var(--font-roboto)!important;
}

/* Admin phase common css start */

.edge-admin {
    background-color: var(--app-background-color);
    padding: 0px 15px 70px;
    margin-top:-1px;
}

.error-block {
    color: #FFF;
    background: #FFB900;
    text-align: center;
    padding: 15px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
}

.custom-error-modal .modal-content {
    background: #1C2C50;
}

.custom-error-modal .modal-content .modal-header {
    border: 0;
    justify-content: end;
}

.custom-error-modal .modal-content .custom-close-icon {
    margin: 0;
}

.custom-error-modal h4.warning-title {
    font-size: 38px;
    line-height: 55px;
    color: #ffffff;
    font-weight: 500;
    margin: 30px 0;
}

.custom-error-modal .custom-modal-body p {
    color: #CCCFD5;
    font-style: italic;
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 30px;
}

/* Admin phase common css end */