.position-ab {
    position: absolute;
}

.position-re {
    position: relative;
}

.position-fi {
    position: fixed;
}

.position-st {
    position: static;
}

.position-in {
    position: inherit;
}

.position-ini {
    position: initial;
}

.position-un {
    position: unset;
}

.position-sti {
    position: sticky;
}

.position-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}