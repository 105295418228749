@media(max-width:1199px) {
    .user-profile-page .row .dropzone-container {
        width: auto;
        height: auto;
    }

    .auth-main.user-profile-page .button-main-compponent {
        margin-top: 0px;
    }

    /* .edge-phase-3-tool .stepper-container .progress {
        max-width: calc(100% - 125px);
        position: relative;
    } */
}

/* 991 - Breakpoint start */
@media(max-width:991px) {
    .main-footer .row-middle {
        display: block;
    }

    .main-footer .col-sm-6 {
        width: 100%;
        text-align: center;
    }

    .main-footer .info-links li {
        display: inline-block;
    }

    .main-footer .info-links {
        display: block !important;
    }

    .main-footer .row-middle .subscribe-section {
        max-width: 600px;
        margin: 20px auto 0;
    }

    .main-footer .row-bottom .social-media {
        justify-content: center;
        margin-top: 15px;
    }

    .main-footer .row .col-sm-12.logo-section {
        padding: 70px 0px 30px;
    }
}

/* 991 - Breakpoint end */

/* 767 - Breakpoint start */
@media(max-width:767px) {
    .main-header .main-top-header {
        display: block !important;
        text-align: center;
        padding: 18px 10px 10px 16px;
    }

    .main-top-header .dropdown-main-component {
        width: 100%;
        margin-top: 15px;
    }

    .main-top-header .dropdown-menu li a {
        position: relative;
    }

    .main-top-header .dropdown-menu li .dropdown_arrow {
        margin-left: 0px;
        padding-right: 0;
        position: absolute;
        right: 10px;
        top: 13px;
    }

    .edge-admin {
        padding: 0px 10px 30px 10px;
    }

    .main-header .breadcrumb h1 {
        font-size: 34px;
    }

    .main-header .breadcrumb h3 {
        margin-top: 0;
        font-size: 22px;
    }

    .App .main-header .breadcrumb {
        padding: 34px 0px 20px;
    }

    .main-header .button-main-compponent .button-field {
        display: inline-block;
        font-size: 14px;
        margin-top: 20px;
    }

    .button-main-compponent .button-field {
        font-size: 14px;
    }

    .main-footer .row .col-sm-12.logo-section img {
        max-width: 70%;
    }

    .main-header .main-top-header img {
        max-width: 70%;
    }

    .main-header .user-profile-header .user-profile-img {
        width: 40px;
        height: 40px;
    }

    .App .main-header {
        /* height: auto; */
    }

    .user-profile-page .row .dropzone-container {
        width: 166px;
        height: 166px;
        display: table;
        margin: 0 auto 20px;
    }

    .edge-admin .nav-btn-section {
        /* display: inline-block !important; */
        width: 50%;
        justify-content: flex-end;
    }

    .edge-phase-3-tool .stepper-actions .button-main-compponent .button-field {
        padding: 22px 22px;
        width: 100% !important;
        margin: 0;
        justify-content: center;
    }

    .edge-phase-3-tool .stepper-actions .right .button-main-compponent .btn-draft {
        margin-bottom: 15px;
    }

    .edge-phase-3-tool .stepper-container .stepper-content {
        margin: 50px 0;
    }

    .edge-phase-3-tool .stepper-container .stepper-actions {
        width: 100%;
        display: flex !important;
    }

    .stepper-actions .prev-button {
        margin-bottom: 15px;
    }

    .edge-phase-3-tool .stepper-container .stepper-actions .button-main-compponent .button-field {
        padding: 20px 26px;
    }

    .edge-phase-3-tool .stepper-container .doc-upload-section .dropzone-container {
        padding: 40px 30px;
    }

    .edge-phase-3-tool .stepper-container .doc-upload-section h3 {
        margin: 30px 0px;
        font-size: 25px;
        line-height: 35px;
    }

    .modal-terms-policy-agreement .custom-modal-body .custom-modal-actions {
        width: 100%;
        display: inline-block!important;
    }

    .modal-terms-policy-agreement .custom-modal-body .button-field {
        height: 58px!important;
        width: 100%!important;
    }

    .modal-terms-policy-agreement .custom-modal-body .button-field.btn-disagree {
        margin-bottom: 15px;
    }

    .modal-terms-policy-agreement .custom-modal-body .info {
        font-size: 26px !important;
    }

    .edge-phase-3-tool .stepper-container .progress {
        max-width: calc(100% - 87px);
    }

    .edge-phase-3-tool .stepper-container .progress {
        width: 100%;
        height: 6px;
        position: absolute;
        top: 22px;
        left: 41px;
        max-width: 89%;
        background-color: #323639;
    }

    .edge-phase-3-tool .step .checkmark,
    .edge-phase-3-tool .step .current-step,
    .edge-phase-3-tool .step .step-count {
        width: 50px;
        height: 50px;
    }

    .edge-phase-3-tool .stepper-progress-container .step-label {
        font-size: 12px;
    }

    .edge-phase-3-tool .step:last-child .step-count {
        background-size: 15px;
    }
}

/* 767 - Breakpoint end */

/* 576 - Breakpoint start */
@media(max-width:576px) {
    .data-table-component .pagination-controls {
        text-align: center;
    }

    .data-table-component .entries-text {
        justify-content: center;
        margin-bottom: 15px;
    }

    .data-table-component ul.pagination {
        justify-content: center;
    }

    .main-footer .footer-menu .info-links li {
        display: block;
        padding: 3px 10px 0 10px;
    }

    .main-footer .col-sm-6 .social-media li {
        margin: 0 10px;
    }

    .form-section .forgot-section {
        display: inline-block !important;
        width: 100%;
        text-align: center;
    }

    .auth-main .forgot-pass-section {
        margin-top: 24px;
    }

    .App .auth-main .button-main-compponent .button-field {
        padding: 22px 10px;
    }

    .App .auth-main.form-section {
        padding: 50px 15px 50px;
    }

    .edge-phase-3-tool .stepper-steps .step-count,
    .edge-phase-3-tool .stepper-steps .checkmark,
    .edge-phase-3-tool .stepper-steps .current-step {
        width: 35px;
        height: 35px;
    }

    .edge-phase-3-tool .stepper-steps .checkmark img {
        max-width: 12px;
    }

    .edge-phase-3-tool .stepper-container .progress {
        max-width: calc(100% - 84px);
        top: 15px;
        left: 45px;
    }

    .edge-admin .edge-phase-3-tool .step-1 h2,
    .edge-admin .edge-phase-3-tool .step-3 h2,
    .edge-admin .edge-phase-3-tool .step-4 h2 {
        font-size: 22px;
        line-height: 26px;
    }

    .edge-admin .edge-phase-3-tool .step-3 .info-container .details {
        font-size: 15px;
    }

    .edge-phase-3-tool .step:last-child .step-count {
        background-size: 13px;
    }

    .modal-terms-policy-agreement .custom-modal-body .custom-modal-actions {
        width: 100%;
        display: inline-block;
    }

    .modal-terms-policy-agreement .custom-modal-body .button-field {
        height: 58px;
        width: 100%;
    }

    .modal-terms-policy-agreement .custom-modal-body .button-field.btn-disagree {
        margin-bottom: 15px;
    }

    .modal-terms-policy-agreement .custom-modal-body .info {
        font-size: 26px !important;
    }

    .templateStep_info{
        margin-top: -1px !important;
    }
}

/* 576 - Breakpoint end */

/* 479 - Breakpoint start */
@media(max-width:479px) {
    .main-footer .row-middle .subscribe-section .subscribe-button {
        position: relative;
        border-radius: 30px;
        width: 100%;
        margin-top: 15px;
    }

    .edge-phase-3-tool .stepper-steps .step-count,
    .edge-phase-3-tool .stepper-steps .checkmark,
    .edge-phase-3-tool .stepper-steps .current-step {
        width: 30px;
        height: 30px;
        font-size: 12px;
    }

    .edge-phase-3-tool .step-label {
        font-size: 12px;
    }

}

/* 479 - Breakpoint end */