.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.cursor-nodrop {
    cursor: no-drop;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.cursor-wait {
    cursor: wait;
}

.cursor-progress {
    cursor: progress;
}

.cursor-cell {
    cursor: cell;
}

.cursor-crosshair {
    cursor: crosshair;
}

.cursor-text {
    cursor: text;
}

.cursor-all-scroll {
    cursor: all-scroll;
}

.pointer-event-none {
    pointer-events: none;
}

.cursor-grab {
    cursor: grab;
}