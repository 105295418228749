.border-dotted {
    border-style: dotted;
}

.border-dashed {
    border-style: dashed;
}

.border-solid {
    border-style: solid;
}

.border-double {
    border-style: double;
}

.border-groove {
    border-style: groove;
}

.border-ridge {
    border-style: ridge;
}

.border-inset {
    border-style: inset;
}

.border-outset {
    border-style: outset;
}

.border-none {
    border-style: none;
}

.border-hidden {
    border-style: hidden;
}