.visibility-hidden {
    visibility: hidden;
}

.visibility-visible {
    visibility: visible;
}

.visibility-collapse {
    visibility: collapse;
}

.visibility-inherit {
    visibility: inherit;
}

.visibility-initial {
    visibility: initial;
}

.visibility-unset {
    visibility: unset;
}

.opacity-none {
    opacity: 0;
}

.opacity-1 {
    opacity: 0.1;
}

.opacity-2 {
    opacity: 0.2;
}

.opacity-3 {
    opacity: 0.3;
}

.opacity-4 {
    opacity: 0.4;
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-6 {
    opacity: 0.6;
}

.opacity-7 {
    opacity: 0.7;
}

.opacity-8 {
    opacity: 0.8;
}

.opacity-9 {
    opacity: 0.9;
}

.opacity-full {
    opacity: 1;
}