/* FLEX */
.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.flex-column-reverse {
    flex-direction: column-reverse;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

/* JUSTIFY */
.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-start {
    justify-content: flex-start;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-around {
    justify-content: space-around;
}

.justify-content-evenly {
    justify-content: space-evenly;
}

/* ALIGN */
.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: flex-end;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-baseline {
    align-items: baseline;
}

.align-items-stretch {
    align-items: stretch;
}

.align-self-center {
    align-self: center;
}

.align-self-end {
    align-self: flex-end;
}

.align-self-start {
    align-self: flex-start;
}

.align-self-baseline {
    align-self: baseline;
}

.align-self-stretch {
    align-self: stretch;
}

.webkit-box-align {
    -webkit-box-align: center;
}