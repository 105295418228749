.d-none {
    display: none;
}

.d-flex {
    display: flex;
}

.d-inline-flex {
    display: inline-flex;
}

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

.d-inline {
    display: inline;
}

.d-table {
    display: table;
}

.d-table-row {
    display: table-row;
}

.d-table-cell {
    display: table-cell;
}

.d-grid {
    display: grid;
}

.d-inline-grid {
    display: inline-grid;
}

.d-content {
    display: contents;
}