.text-decoration-none {
    text-decoration: none;
}

.text-transform-uppercase {
    text-transform: uppercase;
}

.text-alian-left {
    text-align: left!important;
}