.mb-2-px {
    margin-bottom: 2px;
}

.mb-3-px {
    margin-bottom: 3px;
}

.mb-4-px {
    margin-bottom: 4px;
}

.mb-5-px {
    margin-bottom: 5px;
}

.mt-2-px {
    margin-top: 2px;
}

.mt-3-px {
    margin-top: 3px;
}

.mt-4-px {
    margin-top: 4px;
}

.mt-5-px {
    margin-top: 5px;
}

.ml-2-px {
    margin-left: 2px;
}

.ml-3-px {
    margin-left: 3px;
}

.ml-4-px {
    margin-left: 4px;
}

.ml-5-px {
    margin-left: 5px;
}

.mr-2-px {
    margin-right: 2px;
}

.mr-3-px {
    margin-right: 3px;
}

.mr-4-px {
    margin-right: 4px;
}

.mr-5-px {
    margin-right: 5px;
}

.mr-20-px {
    margin-right: 20px;
}

.mt-25-px {
    margin-top: 25px;
}