.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

.overflow-visible {
    overflow: visible;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-x-visible {
    overflow-x: visible;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-y-visible {
    overflow-y: visible;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

body:has(.sidebar__scroll),
body:has(.countrycode__dropdown),
body:has(.project__upgradwrapper-popup) .setting-section-right,
body:has(.project__upgradwrapper-popup) .billing__history-wrapper,
body:has(.billing__address-sidebar) .billing__history-wrapper {
    overflow: hidden;
}